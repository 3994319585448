<template>
  <div class="queue-field">
    <h1 class="text-h3">
      {{ fieldData.title }}
    </h1>
    <div class="q-dd__form">
      <v-text-field
        label="Номер"
        v-model="fieldData.number"
        name="number"
        placeholder="Введите номер"
        outlined
        autofocus
        dense
      />
      <v-text-field
        label="Код"
        v-model="fieldData.orgcode"
        name="orgcode"
        placeholder="Код"
        outlined
        dense
        disabled
        style="width: 80px"
      />
      <v-btn @click="copyUrl" title="Скопировать ссылку" depressed icon>
        <v-icon small>fa-copy</v-icon>
      </v-btn>
    </div>
    <div class="queue-buttons">
      <v-btn @click="reg(fieldData.number, fieldData.pending, fieldData.orgcode)" color="success" :disabled="fieldData.pending">
        Встать в очередь
      </v-btn>
      <v-btn @click="unreg(fieldData.number, fieldData.pending, fieldData.orgcode)" :disabled="fieldData.pending">
        Выйти
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fieldData: {
      type: Object,
      required: true
    },
    reg: {
      type: Function,
      required: true
    },
    unreg: {
      type: Function,
      required: true
    },
    copyUrl: {
      type: Function,
      required: true
    }
  }
}
</script>